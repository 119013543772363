import { Typography } from '@mui/material'
import { composeReactRefs } from '@obeta/utils/lib/composeReactRefs'
import clsx from 'clsx'
import { useRef, forwardRef, useState } from 'react'
import useResizeObserver from '@react-hook/resize-observer'
import styles from './TypographyEllipsis.module.scss'

interface IClasses {
  root?: string
}

interface ITypographyEllipsis extends React.ComponentProps<typeof Typography> {
  numberOfEndCharacters?: number
  children: React.ReactText
  classes?: IClasses
  rightAdornment?: JSX.Element | null
}

/**
 * Allow to render input like this: "het hello...end"
 * "numberOfEndCharacters" determines how many characters will be shown at the end of the input
 * 8 is default
 *
 * I want to use ResizeObserver here, but I don't want to create
 * ResizeObserve instance for every Typography element.
 * Until this make sure that component is rerendered if dimensions change for some reason.
 */
export const TypographyEllipsis = forwardRef<HTMLDivElement, ITypographyEllipsis>((props, ref) => {
  const { numberOfEndCharacters = 8, className, children, classes, rightAdornment, ...rest } = props
  const typographyRef = useRef<HTMLDivElement>(null)
  const [ellipsisMode, setEllipsisMode] = useState(false)

  useResizeObserver(typographyRef, (entry) => {
    const el = entry.target as HTMLElement

    const enableEllipsisMode = el.scrollWidth > el.offsetWidth

    setEllipsisMode(enableEllipsisMode)
  })

  const str = children?.toString() || ''

  // If a rightAdornment is passed, we want to show it on the same line as the base text
  const isSingleLineRoot = rightAdornment && !ellipsisMode

  return (
    <div
      className={clsx(styles.root, { [styles.isSingleLineRoot]: isSingleLineRoot }, classes?.root)}
      title={ellipsisMode ? str : undefined}
    >
      <Typography
        ref={composeReactRefs(typographyRef, ellipsisMode ? undefined : ref)}
        className={clsx(styles.originalTypography, { [styles.hidden]: ellipsisMode }, className)}
        aria-hidden={ellipsisMode}
        {...rest}
      >
        {children}
      </Typography>
      {!ellipsisMode && rightAdornment}

      {ellipsisMode && (
        <div ref={ref} className={styles.ellipsisTypographyWrap}>
          <Typography className={clsx(styles.part1, className)} {...rest}>
            {children}
          </Typography>
          <Typography className={clsx(styles.part2, className)} {...rest} noWrap={false}>
            {str.slice(str.length - numberOfEndCharacters)}
          </Typography>
          {rightAdornment}
        </div>
      )}
    </div>
  )
})
