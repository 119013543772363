import React, { FC } from 'react'
import { useHistory } from '@obeta/data/lib/hooks/useHistoryApi'
import { Link, LinkProps } from '@mui/material'

type ShopLinkProps = {
  trackingActionName?: string
  href: string
}

const LINK_TARGET_BLANK = '_blank'

export const ShopLink: FC<LinkProps & ShopLinkProps> = (props) => {
  const { children, href, trackingActionName, ...rest } = props
  const history = useHistory()

  const ddActionName = trackingActionName ? `CLICK_${trackingActionName}` : undefined
  return (
    <Link
      {...rest}
      href={href}
      onClick={(event) => {
        event.stopPropagation()
        event.preventDefault()
        if (rest.target === LINK_TARGET_BLANK) {
          window.open(href, LINK_TARGET_BLANK)
        } else {
          history.push(href)
        }
      }}
      data-dd-action-name={ddActionName}
    >
      {children}
    </Link>
  )
}
